import React from 'react';
import { Layout } from 'layouts';
import { useTranslation } from 'react-i18next';
import getHeaderLInks from '../shared/headerLinks';
import Constants from '../../static/Constants';
import Helmet from 'react-helmet';
import Button from '@material-ui/core/Button';
// import { navigate } from 'gatsby';
import { withStyles, makeStyles } from '@material-ui/core';
import { Container } from '@material-ui/core';
import VerticalFixedNav from '../styles/verticalFixedNav';
import getStaticImageURL from '../shared/getStaticImageURL';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#2c3b8f',
    color: theme.palette.common.white,
    paddingLeft: '10px !important',
    paddingRight: '10px !important',
    fontFamily: `"Poppins", sans-serif`,
  },
  body: {
    fontSize: 14,
    paddingLeft: '10px !important',
    paddingRight: '10px !important',
    fontFamily: `"Poppins", sans-serif`,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles(theme => ({
  wwhTitle: {
    fontSize: '36px',
    color: '#151515',
    marginTop: '30px',
    marginBottom: '30px',
    fontWeight: 700,
    textAlign: 'center',
    lineHeight: '50px',
  },
  wwhSubtitle: {
    fontSize: '24px',
    color: '#656363',
    fontWeight: 700,
    marginBottom: '15px',
  },
  wwhConentContainer: {
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  organisers: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  organiserLogo: {
    width: '165px',
    marginLeft: '10px',
    marginRight: '10px',
  },
  table: {
    // minWidth: 700,
    fontFamily: `"Poppins", sans-serif`,
  },
  lastDate: {
    fontStyle: 'italic',
    backgroundColor: 'yellow',
    fontSize: '20px',
    fontWeight: '600',
  },
  fao: {
    fontFamily: 'unset',
    fontWeight: 'bold',
    color: '#0059AA',
    fontSize: '20px',
  },
  faoText: {
    marginLeft: '35px',
    marginTop: '-25px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(3),
      marginTop: theme.spacing(-2),
      marginBottom: theme.spacing(2),
      fontSize: '12px',
    },
  },
}));

const WWHConference = ({ ...props }) => {
  const { VerticalFixedNavStyles } = VerticalFixedNav;
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const { pageContext, location } = props;
  const data = pageContext.pageData ? pageContext.pageData : null;
  if (!data) {
    return;
  }
  const programLink = pageContext.programUrl;
  const headerLinks = getHeaderLInks('', programLink, data.noLinks);
  const { pageHeader, headerLogo, headerLogoScroll } = (data || {}).node || {};
  const logos = pageContext.logos || [];
  const footerLogos = pageContext.footerLogos || [];
  const helmetLink = `${Constants.appUrl}/wwh-conference`;

  const homePageInformation =
    t('wwhConferenceHome', {
      returnObjects: true,
    }) || [];

  const getPageDetails = () => {
    if (!ready) {
      return 'Loading...';
    } else {
      return homePageInformation;
    }
  };

  return (
    <Layout
      programLink={programLink}
      title={getPageDetails()?.title}
      content={[getPageDetails()?.subtitle]}
      headerLinks={headerLinks}
      logos={logos}
      footerLogos={footerLogos}
      pageHeader={pageHeader}
      headerLogo={headerLogo}
      headerLogoScroll={headerLogoScroll}
      imageName={'wwh-parallax.jpg'}
      visitedLinks={[{ url: '/', key: 'home', name: 'Home' }]}
      currentPage={{ key: 'wwhConference' }}
      bannerLogoImage="wwh-logo-tp.png"
      bannerLogoLink="https://www.unido.org/world-without-hunger-conference"
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>WWH Conference</title>
        <link rel="canonical" href={helmetLink} />
      </Helmet>
      <div className="container">
        <Container css={VerticalFixedNavStyles}>
          <div>
            <h4 className={classes.wwhTitle}>
              <p
                dangerouslySetInnerHTML={{
                  __html: getPageDetails()?.information?.title,
                }}
              ></p>
            </h4>
          </div>
          <div className={classes.wwhConentContainer}>
            <div className={classes.wwhSubtitle}>
              {getPageDetails()?.information?.overview?.title}
            </div>
            <div>
              <p
                dangerouslySetInnerHTML={{
                  __html: getPageDetails()?.information?.overview?.content,
                }}
              ></p>
              {/* {getPageDetails()?.information?.overview?.content} */}
            </div>
          </div>
          <div className={classes.wwhConentContainer}>
            <div className={classes.wwhSubtitle}>
              {getPageDetails()?.information?.objective?.title}
            </div>
            <div>{getPageDetails()?.information?.objective?.content}</div>
          </div>
          <div className={classes.wwhConentContainer}>
            <div className={classes.wwhSubtitle}>
              {getPageDetails()?.information?.eventHighlight?.title}
            </div>
            <div>
              <ul>
                {getPageDetails()?.information?.eventHighlight?.content?.map(
                  (ele, index) => {
                    return <li key={index}>{ele}</li>;
                  }
                )}
              </ul>
            </div>
          </div>
          <div className={classes.wwhConentContainer}>
            <div className={classes.wwhSubtitle}>
              {getPageDetails()?.information?.organizers?.title}
            </div>
            <div className={classes.organisers}>
              <div>
                <a
                  href="https://www.unido.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    style={{
                      width: '450px',
                      paddingRight: '25px',
                      marginRight: '0px',
                    }}
                    className={classes.organiserLogo}
                    src={getStaticImageURL(
                      `UNIDO_logo_slogan_EN_Light_Blue.png`
                    )}
                    alt="UNIDO"
                    title="UNIDO"
                  ></img>
                </a>
                <div style={{ textalign: 'center' }}>
                  <span className={classes.fao}>
                    <div className={classes.faoText}>
                      {getPageDetails()?.information?.fao}
                    </div>
                  </span>
                </div>
              </div>

              <a href="https://au.int/" target="_blank" rel="noreferrer">
                <img
                  className={classes.organiserLogo}
                  // style={{ width: '195px' }}
                  src={getStaticImageURL(`african-union-logo-full.png`)}
                  alt="African Union"
                  title="African Union"
                ></img>
              </a>
              <a
                href="https://www.ethiopia.gov.et/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className={classes.organiserLogo}
                  src={getStaticImageURL(`goe.png`)}
                  alt="Government of Ethiopia"
                  title="Government of Ethiopia"
                ></img>
              </a>
            </div>
          </div>
          <div className={classes.wwhConentContainer}>
            <div
              className={classes.wwhSubtitle}
              style={{
                textAlign: 'center',
                color: '#2c3b8f',
                fontSize: '26px',
              }}
            >
              {getPageDetails()?.information?.callForProposals?.title}
            </div>
            <div>
              {getPageDetails()?.information?.callForProposals?.content}
            </div>
          </div>
          <div className={classes.wwhConentContainer}>
            <div className={classes.wwhSubtitle}>
              {getPageDetails()?.information?.whyApplying?.title}
            </div>
            <div>
              {/* {getPageDetails()?.information?.whyApplying?.content?.preText} */}
              <p
                dangerouslySetInnerHTML={{
                  __html: getPageDetails()?.information?.whyApplying?.content
                    ?.preText,
                }}
              ></p>
              <ul>
                {getPageDetails()?.information?.whyApplying?.content?.text?.map(
                  (ele, index) => {
                    // return <li key={index}>{ele}</li>;
                    return (
                      <li key={index}>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: ele,
                          }}
                        ></p>
                      </li>
                    );
                  }
                )}
              </ul>
            </div>
          </div>
          <div className={classes.wwhConentContainer}>
            <div className={classes.wwhSubtitle}>
              {getPageDetails()?.information?.whoCanApply?.title}
            </div>
            <div>
              <p>
                {getPageDetails()?.information?.whoCanApply?.content?.preText}
              </p>

              <ol type="1">
                {getPageDetails()?.information?.whoCanApply?.content?.text?.map(
                  (ele, index) => {
                    return <li key={index}>{ele}</li>;
                  }
                )}
              </ol>
            </div>
          </div>
          <div className={classes.wwhConentContainer}>
            <div className={classes.wwhSubtitle}>
              {getPageDetails()?.information?.thematicFocus?.title}
            </div>
            <div>
              {getPageDetails()?.information?.thematicFocus?.content?.preText}
            </div>
            <br />
            <TableContainer>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {getPageDetails()?.information?.thematicFocus?.content?.table?.header?.map(
                      (ele, index) => {
                        return (
                          <StyledTableCell align="center" key={index}>
                            {ele}
                          </StyledTableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {getPageDetails()?.information?.thematicFocus?.content?.table?.tableData.map(
                    (ele, index) => {
                      return (
                        <StyledTableRow key={index}>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            align="center"
                          >
                            {ele.session}
                          </StyledTableCell>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            align="center"
                          >
                            {ele.thematicFocus}
                          </StyledTableCell>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            align="center"
                          >
                            {ele.projectFocus}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className={classes.wwhConentContainer}>
            <div className={classes.wwhSubtitle}>
              {getPageDetails()?.information?.howToApply?.title}
            </div>
          </div>
          <div className="wwh-section">
            <div className="wwh-details">
              <center>
                <img
                  style={{
                    width: '250px',
                    marginTop: '40px',
                    margiBottom: '45px',
                  }}
                  src={getStaticImageURL(`WWH.png`)}
                  alt="WWH"
                  title="WWH"
                ></img>
              </center>
              <p>
                <center>
                  <span>
                    <b>{getPageDetails()?.information?.howToApply?.content}</b>
                  </span>
                </center>
              </p>
              <center>
                <a href="/ACP/iop-details" target="_blank">
                  <Button
                    variant="contained"
                    size="large"
                    className="btn-primary"
                    style={{ marginTop: '10px' }}
                  >
                    {t('clickHere')}
                  </Button>
                </a>
              </center>
            </div>
          </div>
          <div className={classes.wwhConentContainer}>
            <div className={classes.wwhSubtitle} style={{ fontSize: '20px' }}>
              {getPageDetails()?.information?.submissionOfProposal?.title}
            </div>
            <div>
              <p
                dangerouslySetInnerHTML={{
                  __html: getPageDetails()?.information?.submissionOfProposal
                    ?.content,
                }}
              ></p>
            </div>
          </div>
          <div className={classes.wwhConentContainer}>
            <div className={classes.wwhSubtitle} style={{ fontSize: '20px' }}>
              {getPageDetails()?.information?.selectionProcess?.title}
            </div>
            <div>
              {getPageDetails()?.information?.selectionProcess?.content}
            </div>
          </div>
          <div className={classes.wwhConentContainer}>
            <div className={classes.wwhSubtitle} style={{ fontSize: '20px' }}>
              {getPageDetails()?.information?.contact?.title}
            </div>
            <div>
              {homePageInformation?.information?.contact?.content}&nbsp;
              <a
                className="links-blanks"
                href="mailto:investmentportal@unido.org"
              >
                investmentportal@unido.org
              </a>
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default WWHConference;
